import classNames from "classnames";
import FormSignatures from "pages/forms/_components/FormSignatures";
import TransactionTicketCancelConfirmation from "pages/forms/_components/TransactionTicketCancelConfirmation";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, instanceOf, shape, string, node as nodeProp } from "prop-types";
import React, { Component, createRef } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push, replace } from "react-router-redux";
import { actions as formsActions, selectors as formsSelectors } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as transactionsSelectors } from "reducers/transactions";
import * as schedulerUtils from "util/scheduler";
import { getTransactionKind } from "util/transaction";
import * as i18n from "util/i18n";
import * as utilDownload from "util/download";
import { Collapse } from "react-bootstrap";
import { isMobileNative } from "util/device";
import TicketData from "pages/_components/TicketData";
import { isDesktop } from "react-device-detect";
import PageLoading from "pages/_components/PageLoading";
import { simpleMaskEmail } from "util/format";
import Help from "pages/_components/Help";

class TransactionTicket extends Component {
    state = {
        showDetails: false,
    };

    transactionTicketCancelSection = createRef();

    static propTypes = {
        // fromBackoffice: bool,
        isCancelling: bool,
        transaction: shape({
            programed: bool,
            idTransaction: string,
            submitDateTimeAsString: string,
            idTransactionStatus: string,
        }),
        childrenTransactions: arrayOf(
            shape({
                valueDateTime: instanceOf(Date),
                idTransaction: string,
                idTransactionStatus: string,
            }),
        ),
        formActions: shape({}),
        parentTransaction: shape({
            idTransaction: string,
            creationDateTime: instanceOf(Date),
        }),
        children: shape({}),
        // tells if we are rendering to confirm a recent transaction
        // ticketConfirmation: bool,
        dispatch: func,
        isDesktop: bool,
        activeEnvironment: shape({
            permissions: shape({
                requestTransactionCancellation: bool,
            }),
        }).isRequired,
        showSchedulerMessage: bool,
        generalHandleClick: func,
        handleCancel: func,
        isMobileNative: bool.isRequired,
        wally: bool,
        usesJointAccount: bool,
        extraContent: nodeProp,
        fetchingDownload: bool,
        showNotificationMessage: bool,
        emailUserCreator: string,
    };

    static defaultProps = {
        // fromBackoffice: false,
        isCancelling: false,
        // ticketConfirmation: false,
        transaction: {},
        childrenTransactions: null,
        formActions: null,
        parentTransaction: null,
        children: null,
        dispatch: () => {},
        isDesktop: undefined,
        showSchedulerMessage: true,
        generalHandleClick: null,
        handleCancel: null,
        wally: false,
        usesJointAccount: false,
        extraContent: undefined,
        fetchingDownload: false,
        showNotificationMessage: false,
        emailUserCreator: "",
    };

    componentDidUpdate() {
        if (this.transactionTicketCancelSection && this.transactionTicketCancelSection.scrollIntoView) {
            this.transactionTicketCancelSection.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(formsActions.formClosed());
    }

    handleClick = () => {
        this.setState(({ showDetails }) => ({ showDetails: !showDetails }));
    };

    handleCancelTransaction = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(push(`/form/requestTransactionCancellation?referenceToCancel=${idTransaction}`));
    };

    handleDownloadTicket = (idTransaction) => {
        const { dispatch } = this.props;
        if (isMobileNative) {
            dispatch(formsActions.shareTicket(idTransaction, "pdf", null));
        } else {
            dispatch(formsActions.downloadTicket(idTransaction, "pdf", null));
        }
    };

    renderMainData = () => {
        const {
            transaction: {
                idActivity,
                idTransactionStatus,
                data: { mainData, errorMessage },
            },
        } = this.props;

        const label = mainData ? i18n.get(`${idActivity}.${mainData?.label}.label`, mainData?.label) : "";

        return (
            <>
                {idTransactionStatus === "FAILED" ? (
                    <TicketData.Main label="transaction.ticket.FAILED.error.label" value={errorMessage} />
                ) : (
                    mainData && <TicketData.Main {...mainData} label={label} />
                )}
            </>
        );
    };

    renderItemData = () => {};

    renderAdditionalData = () => {
        const {
            transaction: { idActivity, data },
        } = this.props;
        const { additionalDataSections } = data;

        return (
            <>
                {additionalDataSections?.map(({ title, additionalData }, index) => (
                    <>
                        <Row key={index} className="mt-3 mb-4">
                            {title && (
                                <Text bold size="6" labelKey={`${idActivity}.${title}.title`} defaultValue={title} />
                            )}
                        </Row>
                        {additionalData &&
                            Object.keys(additionalData).map((k) => {
                                const label = i18n.get(`${idActivity}.${k}.label`, "");
                                return <TicketData.Data label={label || k} value={additionalData[k]} />;
                            })}
                    </>
                ))}
            </>
        );
    };

    renderButtonDownload = (idTransaction) => {
        const { fetchingDownload, isInvertBtnStyle } = this.props;
        return (
            <Button
                block
                bsStyle={isInvertBtnStyle ? "outline" : "primary"}
                image={isMobileNative ? "images/icons/shareTicket.svg" : "images/icons/download.svg"}
                label={isMobileNative ? "transactions.button.share" : "global.download"}
                onClick={() => this.handleDownloadTicket(idTransaction)}
                loading={fetchingDownload}
            />
        );
    };

    handleDownloadFormCheckBlock = () => {
        const { dispatch } = this.props;
        const sequential = new Date().getTime();
        utilDownload.downloadLink(
            "solicitud_de_revocatoria_" + sequential,
            "/pdf/solicitud_de_revocatoria_23072019.pdf",
        );
    };

    renderFormCheckBlockButton = () => {
        const { fetchingDownload } = this.props;
        return (
            <Button
                block
                bsStyle="primary"
                /* image={isMobileNative ? "images/icons/shareTicket.svg" : "images/icons/download.svg"} Descargar formulario*/
                label="checks.block.ticket.download"
                defaultLabelText="Descargar formulario"
                onClick={() => this.handleDownloadFormCheckBlock()}
                loading={fetchingDownload}
                className="btn-small text-no-wrap"
            />
        );
    };

    hanldeRedirectoToPath = (path, routingMethod) => {
        const { dispatch } = this.props;
        if (routingMethod === "push") {
            dispatch(push(path));
        } else if (routingMethod === "replace") {
            dispatch(replace(path));
        } else {
            dispatch(push(path));
        }
    };

    renderButtons = () => {
        const {
            transaction: { idTransaction },
            isInvertBtnStyle,
            actionSecondary,
        } = this.props;

        return (
            <>
                {actionSecondary ? (
                    <Row
                        gapX="7"
                        className={classNames("mt-7 mb-7", {
                            "row-template-1fr": !isDesktop,
                        })}>
                        {actionSecondary.invertOrder ? (
                            <>
                                <Col xs={isDesktop ? 3 : 12} xsOffset={isDesktop ? 3 : 0}>
                                    {this.renderButtonDownload(idTransaction)}
                                </Col>
                                <Col xs={isDesktop ? 3 : 12}>
                                    <Button
                                        block
                                        bsStyle={isInvertBtnStyle ? "primary" : "outline"}
                                        label={actionSecondary.label}
                                        onClick={
                                            actionSecondary.path
                                                ? () => this.hanldeRedirectoToPath(actionSecondary.path)
                                                : actionSecondary.onClick
                                        }
                                    />
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col xs={isDesktop ? 3 : 12} xsOffset={isDesktop ? 3 : 0}>
                                    <Button
                                        block
                                        bsStyle={isInvertBtnStyle ? "primary" : "outline"}
                                        label={actionSecondary.label}
                                        onClick={
                                            actionSecondary.path
                                                ? () =>
                                                      this.hanldeRedirectoToPath(
                                                          actionSecondary.path,
                                                          actionSecondary?.routingMethod,
                                                      )
                                                : actionSecondary.onClick
                                        }
                                    />
                                </Col>
                                <Col xs={isDesktop ? 3 : 12}>{this.renderButtonDownload(idTransaction)}</Col>
                            </>
                        )}
                    </Row>
                ) : (
                    <Row
                        className={classNames("mt-7", {
                            "row-template-center": isDesktop,
                        })}>
                        <Col xs={isDesktop ? 3 : 12} xsOffset={isDesktop ? 4 : 0}>
                            {this.renderButtonDownload(idTransaction)}
                        </Col>
                    </Row>
                )}
            </>
        );
    };

    render() {
        const {
            childrenTransactions,
            formActions,
            // fromBackoffice,
            isCancelling,
            parentTransaction,
            // ticketConfirmation,
            transaction,
            children,
            activeEnvironment,
            showSchedulerMessage,
            // generalHandleClick,
            handleCancel,
            // isMobileNative,
            usesJointAccount,
            showNotificationMessage,
            emailUserCreator,
        } = this.props;
        const { showDetails } = this.state;
        if (!transaction) {
            return <PageLoading loading />;
        }
        const {
            idTransaction,
            idParentTransaction,
            creationDateTime,
            submitDateTime,
            creationDateTimeAsString,
            data,
        } = transaction;
        const selectedOption = transaction?.data?.scheduler ? transaction.data.scheduler.selectedOption : null;

        const scheduled = selectedOption ? selectedOption !== schedulerUtils.TODAY : false;

        const getDateTime = () => {
            if (idParentTransaction && data?.scheduler?.selectedOption !== "TODAY") {
                return submitDateTime;
            }
            return creationDateTime;
        };

        const statusIcon = () => {
            let icon = "";
            if (
                transaction.idTransactionStatus === "FINISHED" &&
                transaction.idActivity !== "requestTransfers.wally.send"
            ) {
                // icon = "success";
                icon = "success.gif";
                // icon = "pending.svg";
            } else if (transaction.idTransactionStatus === "PARCIAL") {
                icon = "partial.svg";
            } else if (transaction.idTransactionStatus === "SCHEDULED") {
                icon = "success-scheduled.svg";
            } else if (
                transaction.idTransactionStatus === "PROCESSING" ||
                transaction.idTransactionStatus === "DRAFT" ||
                transaction.idActivity === "requestTransfers.wally.send"
            ) {
                icon = "processing.svg";
            } else if (transaction.idTransactionStatus === "PENDING") {
                icon = "pending.svg";
            } else {
                icon = "error.svg";
            }
            return icon;
        };
        const titleKind = getTransactionKind(transaction?.idActivity);
        let labelKeyHeader = `forms.transaction.ticket.status.${transaction.idTransactionStatus}.${titleKind}`;

        const labelCancelDetail = "forms.transfers.confirmation.cancel.detail";

        if (isCancelling) {
            labelKeyHeader = "forms.transfers.confirmation.cancel.title";
        }

        return (
            <>
                <Box
                    background="white"
                    borderRadius="lg"
                    className={classNames("ticket-wrapper box-shadow-small", {
                        "p-7": isDesktop,
                        "p-5": !isDesktop,
                    })}>
                    <Row gapY="5">
                        <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                            <Box component="article" className="ticket">
                                <Box
                                    component="header"
                                    display="flex"
                                    alignX="center"
                                    alignY="center"
                                    className={classNames(`ticket-header ${statusIcon()}`)}>
                                    <Box className="ticket-header-info">
                                        <Box display="flex" alignX="center">
                                            <Image
                                                src={`images/icons/tickets/${statusIcon()}`}
                                                wrapperClassName="ticket-header-image"
                                            />
                                        </Box>
                                        <Box className="mt-6">
                                            <Text className="ticket-header-text ml-4" labelKey={labelKeyHeader} />
                                        </Box>
                                    </Box>
                                </Box>

                                {showNotificationMessage && (
                                    <Box className="text-center mt-5 mb-6">
                                        <Text
                                            labelKey="transaction.ticket.notification.message"
                                            size={isDesktop ? "7" : "6"}
                                        />
                                        <Box className="mt-1">
                                            <Text size="6" bold>
                                                {simpleMaskEmail(emailUserCreator, "x")}
                                            </Text>
                                        </Box>
                                    </Box>
                                )}

                                {transaction?.idActivity == "check.request.send" && (
                                    <Box
                                        background="info"
                                        border="info-border-color"
                                        className="p-5"
                                        display="flex"
                                        borderRadius="md">
                                        <Box>
                                            <Image
                                                src={`images/icons/${`sb-info.svg`}`}
                                                className={classNames("mt-1")}
                                                wrapperWidth="24"
                                                wrapperHeight="24"
                                            />
                                        </Box>
                                        <Box display="flex" flexDirection="column" column="true">
                                            <Text
                                                color="heading"
                                                size="3"
                                                semibold
                                                className={classNames("pl-5")}
                                                labelKey="check.request.ticket.alert.title"
                                            />
                                            <Text
                                                color="heading"
                                                regular
                                                className={classNames("pl-5 mt-2")}
                                                children={i18n.get(`check.request.ticket.alert.message`)}
                                            />
                                        </Box>
                                    </Box>
                                )}

                                {this.renderMainData()}

                                {transaction?.idActivity === "checks.block.send" &&
                                    transaction?.data?.actionKey !== "suspension" &&
                                    transaction?.idTransactionStatus != "FAILED" && (
                                        <>
                                            <Box
                                                background="info"
                                                border="info-border-color"
                                                className="p-5"
                                                display="flex"
                                                borderRadius="md">
                                                <Box>
                                                    <Image
                                                        src={`images/icons/${`sb-info.svg`}`}
                                                        className={classNames("mt-1")}
                                                        wrapperWidth="24"
                                                        wrapperHeight="24"
                                                    />
                                                </Box>
                                                <Box display="flex" flexDirection="column" column="true">
                                                    <Text
                                                        color="heading"
                                                        size="5"
                                                        semibold
                                                        className={classNames("pl-5")}
                                                        labelKey="checks.block.ticket.alert.title"
                                                        defaultValue="¡Importante!"
                                                    />
                                                    <Text
                                                        color="heading"
                                                        size="6"
                                                        regular
                                                        className={classNames("pl-5 pr-5 mt-2")}
                                                        children={i18n.get(
                                                            `checks.block.ticket.alert.message`,
                                                            `Para solicitar el bloqueo permanente, entregue "Formulario medidas de bloqueo definitivas" en la oficina más cercana en un plazo de 72 horas.`,
                                                        )}
                                                    />
                                                </Box>
                                            </Box>

                                            <Box className="mt-5 align-items-center-child smalltext-help">
                                                <Help
                                                    titleKey={`${transaction?.idActivity}.download.${transaction?.data?.actionKey}.help.title`}
                                                    textKey={`${transaction?.idActivity}.download.${transaction?.data?.actionKey}.help.text`}
                                                    defaultValueTitle="Descargar formulario medidas de bloqueo definitivas."
                                                    defaultValueText="Este formulario le permite aplicar medidas permanentes o definitivas."
                                                    iconSrc="images/icons/icon_download.svg"
                                                    children={this.renderFormCheckBlockButton()}
                                                />
                                            </Box>
                                        </>
                                    )}

                                <Box display="flex" alignX="center" className="mb-7">
                                    <Button
                                        className="button-see-more"
                                        image={`images/util/arrow-${showDetails ? "down" : "up"}.svg`}
                                        label={showDetails ? "transactions.link.seeMore" : "transactions.link.seeLess"}
                                        onClick={this.handleClick}
                                        bsStyle={this.actionSecondary ? "link-without-underline" : "link"}
                                        imageRight
                                    />
                                </Box>

                                <Collapse in={!showDetails}>
                                    <Box>
                                        <Box
                                            background="background-disabled"
                                            className={classNames("p-5", {
                                                "my-5": isDesktop,
                                                "my-2": !isDesktop,
                                            })}
                                            borderRadius="lg">
                                            <Row
                                                className={classNames("mt-3 mb-4", {
                                                    "row-template-1fr gapY-1": !isDesktop,
                                                })}>
                                                <Col xs={6}>
                                                    <Text size="6" labelKey="transaction.ticket.order.label" />
                                                </Col>
                                                <Col
                                                    xs={6}
                                                    className={classNames({
                                                        "text-right": isDesktop,
                                                    })}>
                                                    <Text size="6" bold>
                                                        {idTransaction}
                                                    </Text>
                                                </Col>
                                            </Row>
                                            <Row
                                                className={classNames("mt-3 mb-4", {
                                                    "row-template-1fr gapY-1": !isDesktop,
                                                })}>
                                                <Col xs={6}>
                                                    <Text size="6" labelKey="transaction.ticket.date.label" />
                                                </Col>
                                                <Col
                                                    xs={6}
                                                    className={classNames("text-bold size-6", {
                                                        "text-right": isDesktop,
                                                    })}>
                                                    {idParentTransaction &&
                                                    data?.scheduler?.selectedOption !== "TODAY" ? (
                                                        <FormattedDate
                                                            date={this.getDateTime(
                                                                data,
                                                                creationDateTime,
                                                                idParentTransaction,
                                                                submitDateTime,
                                                            )}
                                                            anotherFormat="DD/MM/YYYY HH:mm:ss"
                                                        />
                                                    ) : (
                                                        <>{creationDateTimeAsString}</>
                                                    )}
                                                </Col>
                                            </Row>
                                            {this.renderAdditionalData()}
                                            {activeEnvironment.type === "corporate" && (
                                                <FormSignatures
                                                    transaction={transaction}
                                                    isDesktop={isDesktop}
                                                    isInTicket
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Collapse>
                                <Box
                                    className={classNames("default-ticket-content zig-zag-border", {
                                        "success-ticket":
                                            statusIcon() === "success" || statusIcon() === "success-scheduled",
                                        "partial-ticket": statusIcon() === "partial",
                                    })}
                                    fullWidth
                                    background="white"
                                    position="relative">
                                    {children ? (
                                        <Box className="data-children display-contents mb-7">
                                            {children}
                                            {showSchedulerMessage && scheduled && (
                                                <div className="data-wrapper mx-7">
                                                    <I18n
                                                        id="forms.confirmation.scheduler"
                                                        componentProps={{ className: "data-label" }}
                                                    />
                                                    <div>
                                                        <Message value={transaction.data.scheduler} />
                                                    </div>
                                                </div>
                                            )}
                                        </Box>
                                    ) : null}

                                    <Box className="ticket-info-wrapper-secondary-t">
                                        {parentTransaction && !usesJointAccount && (
                                            <>
                                                <Text
                                                    component="h4"
                                                    className="data-title"
                                                    labelKey="scheduler.title"
                                                />

                                                <Box display="flex" alignX="between" alignY="center">
                                                    <Box display="flex">
                                                        <Text
                                                            className="data-label mr-3"
                                                            labelKey="forms.transaction.ticket.createdDate.label"
                                                        />
                                                        <FormattedDate
                                                            size="7"
                                                            date={parentTransaction.creationDateTime}
                                                        />
                                                    </Box>
                                                    <Button
                                                        href={`/transaction/${parentTransaction.idTransaction}`}
                                                        bsStyle="link"
                                                        label="forms.transaction.ticket.detail.label"
                                                    />
                                                </Box>
                                            </>
                                        )}

                                        {childrenTransactions && childrenTransactions.length > 0 && (
                                            <Row>
                                                <Col xs={12}>
                                                    <Text
                                                        component="h4"
                                                        labelKey="forms.transaction.ticket.scheduled.children.title"
                                                        className="data-title"
                                                    />
                                                    {childrenTransactions.map((child) => (
                                                        <>
                                                            <Box className="link-wrapper" fullWidth>
                                                                <Link to={`/transaction/${child.idTransaction}`}>
                                                                    <Box
                                                                        display="flex"
                                                                        alignY="baseline"
                                                                        alignX="between"
                                                                        fullWidth>
                                                                        <Box
                                                                            display="flex"
                                                                            className="mr-3"
                                                                            alignY="baseline"
                                                                            fullWidth>
                                                                            <FormattedDate
                                                                                color="heading"
                                                                                size="7"
                                                                                bold
                                                                                date={child.valueDateTime}
                                                                            />
                                                                            <Box className="d-grid" withEllipsis>
                                                                                <Text
                                                                                    className="id-ticket ml-2"
                                                                                    size="small"
                                                                                    ellipsis>
                                                                                    {child.idTransaction}
                                                                                </Text>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box
                                                                            display="flex"
                                                                            alignY="center"
                                                                            alignX="center"
                                                                            borderRadius="sm"
                                                                            className={classNames(
                                                                                "state-wrapper px-3",
                                                                                {
                                                                                    "background-error":
                                                                                        child.idTransactionStatus ===
                                                                                            "FAILED" ||
                                                                                        child.idTransactionStatus ===
                                                                                            "CANCELLED" ||
                                                                                        child.idTransactionStatus ===
                                                                                            "DELETED" ||
                                                                                        child.idTransactionStatus ===
                                                                                            "RETURNED",

                                                                                    "background-warning-border-color":
                                                                                        child.idTransactionStatus ===
                                                                                        "PROCESSING",
                                                                                    "background-warning":
                                                                                        child.idTransactionStatus ===
                                                                                            "PENDING" ||
                                                                                        child.idTransactionStatus ===
                                                                                            "DRAFT" ||
                                                                                        child.idTransactionStatus ===
                                                                                            "SCHEDULED",
                                                                                    "background-success":
                                                                                        child.idTransactionStatus ===
                                                                                        "FINISHED",
                                                                                    "background-partial":
                                                                                        child.idTransactionStatus ===
                                                                                        "PARCIAL",
                                                                                },
                                                                            )}>
                                                                            <Text
                                                                                color="text-boton"
                                                                                size="7"
                                                                                bold
                                                                                ellipsis
                                                                                labelKey={`transaction.status.${child.idTransactionStatus}`}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Link>
                                                            </Box>
                                                        </>
                                                    ))}
                                                </Col>
                                            </Row>
                                        )}
                                    </Box>
                                    {/* {!wally && idTransaction && (
                                    <Box className="px-7 pb-7">
                                        <Text
                                            size="7"
                                            color="heading"
                                            labelKey="forms.transaction.ticket.scheduled.idTransaction.label"
                                        />
                                        <Text size="7" color="heading">
                                            {usesJointAccount && idParentTransaction
                                                ? idParentTransaction
                                                : idTransaction}
                                        </Text>
                                    </Box>
                                )} */}

                                    {/* {statusIcon() !== "processing" &&
                                statusIcon() !== "pending" &&
                                transaction?.idTransactionStatus !== "SCHEDULED" &&
                                transaction?.data?.typeTransaction !== "PARENT" &&
                                !isMobileNative ? (
                                    <Box display="flex" alignX="center" className="mt-auto pb-6">
                                        <Button
                                            className="px-5"
                                            bsStyle="link"
                                            image="images/icons/download.svg"
                                            label="global.download"
                                            onClick={() => generalHandleClick("downloadTicketPdf")}
                                        />
                                    </Box>
                                ) : null} */}
                                    {/* {extraContent &&
                                    transaction?.idTransactionStatus &&
                                    transaction.idTransactionStatus !== "SCHEDULED" &&
                                    extraContent(transaction)} */}
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                            {isCancelling ? (
                                <section
                                    ref={(node) => {
                                        this.transactionTicketCancelSection = node;
                                    }}>
                                    <Box display="flex" alignX="center" fullWidth>
                                        <TransactionTicketCancelConfirmation
                                            idTransaction={idTransaction}
                                            handleCancelPreview={handleCancel}
                                            labelConfirm={labelCancelDetail}
                                            scheduled={scheduled}
                                        />
                                    </Box>
                                </section>
                            ) : (
                                <div className="d-flex-justify-content-center">
                                    <div className={isDesktop && "ml-1"}>{formActions}</div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    {!isDesktop && <>{this.renderButtons()}</>}
                </Box>
                {/* {actionSecondary ? (
                    <Row gapX="7" className="mt-7 mb-7">
                        <>
                            <Col xs={3} xsOffset={3}>
                                <Button
                                    block
                                    bsStyle="outline"
                                    label={actionSecondary.label}
                                    onClick={actionSecondary.onClick}
                                />
                            </Col>
                            <Col xs={3}>
                                {this.renderButtonDownload(idTransaction)}
                            </Col>
                        </>
                    </Row>
                ) : (
                    <Row className="mt-7 row-template-center">
                        <Col xs={3} xsOffset={4}>
                            {this.renderButtonDownload(idTransaction)}
                        </Col>
                    </Row>
                )} */}
                {isDesktop && <>{this.renderButtons()}</>}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isCancelling: formsSelectors.getIsCancellingTransaction(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    actionSecondary: transactionsSelectors.getActionSecondary(state),
    showNotificationMessage: transactionsSelectors.getShowNotificationMessage(state),
    emailUserCreator: formsSelectors.getEmailUserCreator(state),
    fetchingDownload: formsSelectors.isFetchingDownloadTicket(state),
    isInvertBtnStyle: transactionsSelectors.isInvertBtnStyle(state),
});

export default connect(mapStateToProps)(resizableRoute(TransactionTicket));
